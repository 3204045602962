import { CaseReducerActions, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './'
import { HYDRATE } from 'next-redux-wrapper'
import { SliceActionCreator } from '@reduxjs/toolkit/src/createSlice'

// Type for our state
export interface FiltersState {
  filtersState: {
    // filters: {
    //   date: [Dayjs, Dayjs]
    // }
    options: any
  }
}

// Initial state
const initialState: FiltersState = {
  filtersState: {
    // filters: {
    //   date: [dayjs('2022-11-01T00:00:00.000Z'), dayjs('2022-11-30T00:00:00.000Z')],
    // },
    options: null,
  },
}

// Actual Slice
export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // Action to set the filters state
    setFiltersState(state, { payload }) {
      state.filtersState = payload
    },

    // Action to set the filters state
    setOptions(state, { payload }) {
      state.filtersState.options = payload
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.filters,
      }
    },
  },
})

export const { setFiltersState, setOptions } = filtersSlice.actions as any

export const selectFiltersState = (state: AppState) => state.filters.filtersState
export const selectFiltersOptions = (state: AppState) => selectFiltersState(state).options

export default filtersSlice.reducer
