import styled from 'styled-components'
import { Checkbox as ACheckbox, Input as AInput } from 'antd'

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  max-width: 272px;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.07);
  }
  .ant-checkbox-group {
    max-height: 590px;
    overflow: auto;
  }
`

export const Header = styled.header`
  display: flex;
  margin-bottom: 12px;

  svg {
    margin-right: 12px;
  }
`

export const Title = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
`

export const Input = styled(AInput)`
  && {
    background: transparent;

    input {
      background: transparent;
      color: rgba(255, 255, 255, 0.4);
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
`

export const Text = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  max-width: 196px;
  width: 180px;
`

export const Icon = styled.i`
  visibility: hidden;
  opacity: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 8px;
  top: 8px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(1.2px);
  display: flex;
  justify-content: center;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
`

export const Content = styled.div`
  display: flex;
  position: relative;
  padding: 8px;
  border-radius: 14px;
  min-height: 56px;
`
export const Checkbox = styled(ACheckbox)`
  && {
    .ant-checkbox-inner {
      display: none;
    }

    &:not(&.ant-checkbox-wrapper-checked) {
      ${Text} {
        text-decoration: line-through;
      }

      ${Content} {
        opacity: 0.4;
      }
    }

    &.ant-checkbox-wrapper {
      width: 100%;

      &.ant-checkbox-wrapper-checked {
        &:hover {
          ${Icon} {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &:hover {
        ${Content} {
          background: rgba(0, 0, 0, 0.15);
        }
      }
    }

    .ant-checkbox-checked {
      &::after {
        border: none;
      }
    }
  }
`

export const Tag = styled.span`
  min-width: 12px;
  height: 12px;
  background: ${({ color }) => color};
  border-radius: 3px;
  display: inline-block;
  margin-top: 4px;
  margin-right: 8px;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  .ant-checkbox-wrapper {
    margin-inline-start: 0;
  }
`
