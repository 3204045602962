'use client'

import React, { useRef } from 'react'
import type { NextPage } from 'next'
import { wrapper } from '@core/store'
import { DashboardHeader, DashboardMain } from '@ui/organisms/dashboard'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { useStore } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import MainTemplate from '@/components/layout/main'

export const DEFAULT_DATE = [dayjs('2022-11-01T00:00:00.000Z'), dayjs('2022-11-30T00:00:00.000Z')]

const DashboardPage: NextPage<any> = ({ query }) => {
  const store: any = useStore()
  const { dateFrom, dateTo, category, campaigns, region } = query
  const initialStore = useRef<any>({
    date: dateFrom ? [dayjs(dateFrom as string), dayjs(dateTo as string)] : DEFAULT_DATE,
    category: category,
    region: region,
    campaigns: campaigns || [],
  })

  return (
    <PersistGate persistor={store.__persistor} loading={null}>
      <MainTemplate>
        <Formik
          enableReinitialize
          initialValues={initialStore.current}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
        >
          <>
            {initialStore.current && <DashboardHeader />}
            <DashboardMain />
          </>
        </Formik>
      </MainTemplate>
    </PersistGate>
  )
}

DashboardPage.getInitialProps = ({ query }) => {
  return { query }
}

export default wrapper.withRedux(DashboardPage)
