import React from 'react'
import { Spin } from 'antd'
import FilterInputs from '@/components/ui/organisms/dashboard/components/dashboardFliter/filterInputs'
import useFilterHook from '@/components/ui/organisms/dashboard/components/dashboardFliter/useFilterHook'
import * as S from './styled'

const DashboardFilter: React.FC = () => {
  const { reportsOptionsData, loading } = useFilterHook()

  return (
    <S.Section>
      {(!loading && reportsOptionsData && <FilterInputs options={reportsOptionsData} />) || <Spin />}
    </S.Section>
  )
}

export default DashboardFilter
