import { createWrapper } from 'next-redux-wrapper'
import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { Env } from '@/utils/env'
import { appSlice } from './appSlice'
import { defaultApi } from './authSlice'
import { filtersSlice } from './repotsSlice'

const makeConfiguredStore = (reducer: any) =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([logger, defaultApi.middleware]),
  })

const makeStore = () => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    return makeConfiguredStore(
      combineReducers({
        [defaultApi.reducerPath]: defaultApi.reducer,
        [filtersSlice.name]: filtersSlice.reducer,
        [appSlice.name]: appSlice.reducer,
      }),
    )
  } else {
    // we need it only on client side
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { persistStore, persistReducer } = require('redux-persist')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const storage = require('redux-persist/lib/storage').default

    const persistConfig = {
      key: 'nextjs',
      whitelist: ['appConfig'], // make sure it does not clash with server keys
      storage,
    }

    const persistedReducer = persistReducer(
      persistConfig,
      combineReducers({
        [defaultApi.reducerPath]: defaultApi.reducer,
        [filtersSlice.name]: filtersSlice.reducer,
        [appSlice.name]: appSlice.reducer,
      }),
    )
    const store: any = makeConfiguredStore(persistedReducer)

    store.__persistor = persistStore(store) // Nasty hack

    if (typeof window !== 'undefined' && Env.isDev()) {
      Object.assign(window, { __store: store })
    }

    return store
  }
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

export const wrapper = createWrapper<AppStore>(makeStore)
