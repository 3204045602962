import { useCallback, useEffect, useRef, useState } from 'react'
import { Axios, AxiosInstance, AxiosResponse } from 'axios'
import { useCookies } from 'react-cookie'
import { API, ApiInternal } from '../internal-api'

interface RequestStore {
  loading: boolean
  isFetched: boolean
  error?: any
  data?: any
}

const initialState: RequestStore = { loading: false, isFetched: false, error: null, data: null }

interface UseRequestConfig {
  method?: keyof Pick<typeof ApiInternal, 'get' | 'post' | 'delete' | 'put'>
  url: string
  config?: Record<string, unknown>
}

interface UseRequestOptions {
  isDisable?: boolean
  isShouldUpdate?: boolean
  updateEvery?: number
}

const successResponse = (response: AxiosResponse): boolean => {
  return response.status >= 200 && response.status < 300 && Boolean(response.data)
}

function useRequest({ method = 'get', url, config }: UseRequestConfig, options?: UseRequestOptions): RequestStore {
  const [state, setState] = useState(initialState)
  const initialize = useRef({ firstLoad: false, loading: false })

  // console.debug('session >>> >> >', session)

  const loadData = useCallback(() => {
    setState((prev) => ({ ...prev, loading: !prev.loading }))

    const request = ApiInternal[method]

    request(url, { ...config })
      .then((res: AxiosResponse<any>) => {
        if (successResponse(res)) {
          initialize.current.loading = false
          setState({ loading: false, isFetched: true, data: res?.data, error: null })
        }
      })
      .catch((error: any) => {
        initialize.current.loading = false
        setState({ loading: false, isFetched: true, data: null, error })
      })
  }, [url, config])

  useEffect(() => {
    let interval: any
    const loadMore = !options?.isShouldUpdate ? !initialize.current.firstLoad : true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!options.isDisable && loadMore && !initialize.current.loading) {
      initialize.current.firstLoad = true
      initialize.current.loading = true
      loadData()

      if (options?.updateEvery) {
        interval = setInterval(
          async () => {
            loadData()
          },
          options?.updateEvery,
        )

        return () => clearInterval(interval)
      }
    }
  }, [url, options?.isDisable, options?.updateEvery, initialize.current])

  return state
}

export default useRequest
