import styled from 'styled-components'
import { DatePicker, Select as FSelect } from 'antd'

const { RangePicker } = DatePicker

export const Select = styled(FSelect)`
  max-width: 220px;
  width: 100%;

  && {
    .ant-select-arrow{
      color: rgba(255, 255, 255, 0.6);
    }
    
    .ant-select-selector {
      height: 40px;
      background: transparent;
      color: rgba(255, 255, 255, 0.6);

      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 12px;

      .ant-select-selection-search {
        display: flex;
        align-items: center;
      }
      

      svg {
        fill: rgba(255, 255, 255, 0.6);
      }
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      color: rgba(255, 255, 255, 0.6);
      line-height: 40px;
      vertical-align: middle;
    }
    }
  
    .ant-select-selection-item-content {
      display: flex;
      align-items: center;
    }
  }
`

export const StyledRangePicker = styled(RangePicker)`
  && {
    background: transparent;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    min-width: 250px;
    height: 40px;

    &:hover,
    &.ant-picker-focused {
      border-color: #4096ff;
    }

    &&.ant-picker .ant-picker-clear {
      background: #171717;
    }

    svg {
      fill: rgba(255, 255, 255, 0.6);
    }

    input {
      color: rgba(255, 255, 255, 0.6);

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`
