import React from 'react'
import { Description, Section, Title } from '@/components/layout/page'
import DashboardFilter from '@/components/ui/organisms/dashboard/components/dashboardFliter'

export const DashboardHeader = () => {
  return (
    <Section>
      <Title>Info Field Analysis</Title>
      <Description>Here you can view available campaigns and detailed information on each.</Description>
      <DashboardFilter />
    </Section>
  )
}

export default DashboardHeader
