import styled from 'styled-components'
import { Button as AButton, Modal as AModal } from 'antd'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;
`

export const Button = styled(AButton)`
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.25);
  right: 10px;

  &:last-of-type {
    right: inherit;
    left: 10px;
  }
`

export const Title = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  padding: 0 10px;
`

export const Overflow = styled.div`
  max-height: 480px;
  overflow: hidden;
`

export const Container = styled.div`
  max-width: 280px;
  min-width: 280px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  color: #fff;
  opacity: 0.5;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`

export const Value = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TagRow = styled.div`
  display: flex;
`

export const Tag = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  margin: 3px 3px;
`

export const Tags = styled.section`
  margin-bottom: 15px;
`

export const Icon = styled.i`
  margin-right: 6px;
  padding: 0;
  min-width: 14px;
  height: 14px;
`

export const Info = styled.section`
  margin-bottom: 15px;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 100px;
`

export const InfoSection = styled.div`
  margin-bottom: 25px;
  padding-bottom: 10px;
  white-space: pre-wrap;
`

export const InfoSectionTitle = styled.h4`
  margin-bottom: 10px;
  color: #f6b2b2;
  top: 0;
`

export const Modal = styled(AModal)`
  && {
    left: 0;
    top: 0;
    height: 100%;
    margin-right: 0;
    margin-left: auto;
    padding: 0;
    font-family: 'Montserrat', sans-serif;

    .ant-modal-mask {
      background: transparent;
    }

    .ant-modal-content {
      border-radius: 0;
      background: rgba(47, 45, 52, 0.6);
      height: 100%;
      color: #fff;
      padding: 20px 66px;
    }

    .ant-modal-header {
      background: none;
    }

    .ant-modal-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #fff;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-close {
      right: inherit;
      top: 38px;
      left: -14px;
      background: #25222a;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: 1.5px solid rgba(255, 255, 255, 0.1);
    }
  }
`
