import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { selectAppConfig } from '@core/store/appSlice'
import { setOptions } from '@core/store/repotsSlice'
import getUniqueColor from '@core/utils/getUniqueColor'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import useRequest from '@/apis/external-api/useRequest'
import { API, ApiInternal } from '@/apis/internal-api'
import { REGIONS } from './constants'

export default function useFilterHook() {
  const dispatch = useDispatch()
  const { lang } = useSelector(selectAppConfig) as { lang: string }
  const router = useRouter()
  const { setFieldValue } = useFormikContext<any>()

  const {
    data: reportsOptionsData,
    loading,
    error,
  } = useRequest(
    {
      url: `${API.CHART_SERVER.INDEX}/reports/options?lang=${lang}`,
    },
    { isShouldUpdate: true },
  )

  useEffect(() => {
    router.replace('', undefined, { shallow: true })
    setFieldValue('category', undefined)
  }, [lang])

  const retortsOptionsResponse = {
    ...(reportsOptionsData || {}),
    regions: REGIONS,
    colors: (reportsOptionsData?.campaigns || []).reduce((acc: any, campaign: any, i: number) => {
      acc[campaign.value as string] = getUniqueColor(i)
      return acc
    }, {}),
  }

  useEffect(() => {
    dispatch(setOptions(retortsOptionsResponse))
  }, [reportsOptionsData])

  return {
    reportsOptionsData: retortsOptionsResponse,
    loading,
    error,
  }
}
