import React, { useCallback, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { useRouter } from 'next/router'
import { Select, StyledRangePicker } from '@ui/atoms/customMultiselect/styled'

const FilterInputs: React.FC<any> = ({ options }) => {
  const router = useRouter()
  const memoRouter = useRef(router)
  const { values, setFieldValue } = useFormikContext<any>()

  const handleUpdateRouter = useCallback(() => {
    memoRouter.current.push(router)
  }, [memoRouter.current.query])

  useEffect(() => {
    if (values?.region) {
      memoRouter.current.query.region = values.region
      handleUpdateRouter()
    }
    if (values?.category) {
      memoRouter.current.query.category = values.category
      handleUpdateRouter()
    }
    if (values?.campaigns) {
      memoRouter.current.query.campaigns = values.campaigns
      handleUpdateRouter()
    }
    if (values?.date) {
      memoRouter.current.query.dateFrom = values.date?.[0].toISOString()
      memoRouter.current.query.dateTo = values.date?.[1].toISOString()
      handleUpdateRouter()
    }
  }, [values, handleUpdateRouter])

  const handleUpdateSelect = (name: string) => (value: unknown) => {
    setFieldValue(name, value)
  }

  return (
    <>
      <Select
        onChange={handleUpdateSelect('region')}
        value={values.region}
        placeholder="Region"
        showArrow
        options={options?.regions}
      />

      <Select
        onChange={handleUpdateSelect('category')}
        value={values.category}
        placeholder="Сategory"
        showArrow
        showSearch
        options={options?.categories}
      />
      <StyledRangePicker onChange={handleUpdateSelect('date')} value={values.date} name="date" />
    </>
  )
}

export default FilterInputs
