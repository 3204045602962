import React from 'react'
import { items, selectAppConfig, setAppConfig } from '@core/store/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from '@/components/ui/atoms/dropdown'
import LangIcon from '@/components/ui/atoms/icon/assets/lang.svg'
import * as S from './styled'

export const LangNavigation = () => {
  const dispatch = useDispatch()
  const { lang } = useSelector(selectAppConfig)
  const handleItemChange = (data: any) => {
    dispatch(setAppConfig({ lang: data.key }))
  }

  return (
    <S.LangButtonWrapper>
      <Dropdown items={items} selectable onSelect={handleItemChange} defaultSelectedKeys={[lang]} placement="bottom">
        <div>
          <S.LangButton shape="circle" icon={<LangIcon width={24} />} />
        </div>
      </Dropdown>
    </S.LangButtonWrapper>
  )
}

export default LangNavigation
