import styled from 'styled-components'

export const Section = styled.section`
  flex-basis: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  input {
    background: transparent;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
