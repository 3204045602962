import styled from 'styled-components'

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1248px;
  margin: 0 auto;
  padding-top: 56px;
  position: relative;

  @media screen and (max-width: 1652px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Title = styled.h1`
  flex-basis: 700px;
  font-size: 36px;
  line-height: 44px;
  color: #fff;
  margin: 0;
`

export const Description = styled.p`
  flex-basis: 700px;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 40px;
`
