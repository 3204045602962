import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import CloseIcon from '@/components/ui/atoms/icon/assets/close.svg'
import Info from '@/components/ui/organisms/dashboard/components/accessibleLineChart/info'
import Tags from '@/components/ui/organisms/dashboard/components/accessibleLineChart/tags'
import * as S from '../styled'

const ChartModal: React.FC<any> = ({ isOpen, handleClose, data, handleNextPoint, handlePrevPoint }) => {
  return (
    <S.Modal
      mask={false}
      closeIcon={<CloseIcon height={12} width={12} />}
      title="Full Resume"
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <S.Button icon={<RightOutlined />} onClick={handleNextPoint} />
      <S.Button icon={<LeftOutlined />} onClick={handlePrevPoint} />
      <Tags
        maxWidth={242}
        date={data?.date}
        category={data?.category}
        campaign={data?.campaign}
        region={data?.region}
      />
      <Info place={data?.place} coverage={data?.coverage} shift={data?.shift} resume={data?.resume} />
    </S.Modal>
  )
}

export default ChartModal
