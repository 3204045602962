import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 100px;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  max-height: 700px;
  overflow: hidden;
  border-radius: 18px;
  max-width: 1248px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(28px);

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, #9573a7 0%, #414477 100%);
    filter: blur(460px);
    border-radius: 18px;
    z-index: 0;
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
