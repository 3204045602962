import React from 'react'
import { isEmpty } from 'lodash'
import * as S from '@/components/ui/organisms/dashboard/components/accessibleLineChart/styled'

const Info: React.FC<any> = ({ place, coverage, shift, resume, isDisabled }) => {
  console.debug('shift >>> >> >', shift)
  return (
    <S.Info>
      <S.InfoSection>
        <S.InfoSectionTitle>{!isDisabled ? 'Dynamic analysis:' : 'No data'}</S.InfoSectionTitle>
        <p>
          {!isDisabled &&
            `This topic reached the {place}th place in popularity today. Makes up ${coverage}% of all posts. Decreased by ${shift} point compared to the previous 7 days.`}
        </p>
      </S.InfoSection>
      {!isDisabled && (
        <S.InfoSection>
          <S.InfoSectionTitle>Main theses:</S.InfoSectionTitle>
          <p>{resume}</p>
        </S.InfoSection>
      )}
    </S.Info>
  )
}

export default Info
