import React from 'react'
import { selectFiltersOptions } from '@core/store/repotsSlice'
import useMainHook from '@ui/organisms/dashboard/main/useMainHook'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { useFullscreenContext } from '@/components/layout/full-screen/FullscreenContext'
import AccessibleLineChart from '@/components/ui/organisms/dashboard/components/accessibleLineChart'
import Aside from '@/components/ui/organisms/dashboard/components/dashboardFliter/aside'
import { LangNavigation } from '../LangNavigator'
import * as S from './styled'

export const DashboardMain = () => {
  const reportsOptions = useSelector(selectFiltersOptions)
  const { series, categories, loading, error } = useMainHook()

  const { headerControlsEl } = useFullscreenContext()

  return (
    <S.Container>
      {headerControlsEl && ReactDOM.createPortal(<LangNavigation />, headerControlsEl)}
      {!!error && <p style={{ textAlign: 'center' }}>{error.message}</p>}
      <S.Wrapper>
        <Aside campaigns={reportsOptions?.campaigns} colors={reportsOptions?.colors} />
        {!loading && !!series && !!categories && <AccessibleLineChart series={series} categories={categories} />}
        {loading && (
          <S.SpinnerWrapper>
            <Spin />
          </S.SpinnerWrapper>
        )}
      </S.Wrapper>
    </S.Container>
  )
}

export default DashboardMain
