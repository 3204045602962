import React, { useEffect, useRef, useState } from 'react'
import { Chart, TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from 'highcharts'
import ReactDOM from 'react-dom'
import Info from '@/components/ui/organisms/dashboard/components/accessibleLineChart/info'
import Tags from '@/components/ui/organisms/dashboard/components/accessibleLineChart/tags'
import * as S from '../styled'

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`

interface Props {
  chart: Chart | null
  children(formatterContext: TooltipFormatterContextObject): JSX.Element
}

const Tooltip = ({ chart, children }: Props) => {
  const isInit = useRef(false)
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(null)

  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function () {
        console.debug('this.point >>> >> >', this.point)
        // Ensures that tooltip DOM container is rendered before React portal is created.
        if (!isInit.current && this.point.y === 0) {
          isInit.current = true

          chart.tooltip.refresh.apply(chart.tooltip, [this.point])
          chart.tooltip.hide(0)
        }

        setContext(this)

        return `<div id="${generateTooltipId(chart.index)}"></div>`
      }

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
        },
      })
    }
  }, [chart])

  const node = chart && document.getElementById(generateTooltipId(chart.index))

  return node && context ? ReactDOM.createPortal(children(context), node) : null
}

const TooltipComponent: React.FC<any> = ({ chart }) => {
  const [isDisabledTooltip, setIsDisabledTooltip] = useState(false)
  return (
    <>
      {!isDisabledTooltip && (
        <Tooltip chart={chart}>
          {(formatterContext) => {
            const { point } = formatterContext

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const state = point.series?.options.state[point.index]
            const isDisabled = state.disable === true
            return (
              <>
                <S.Overflow>
                  <S.Container>
                    <Tags
                      maxWidth={155}
                      date={state?.date}
                      category={state?.category}
                      campaign={state?.campaign}
                      region={state?.region}
                    />
                    <Info
                      isDisabled={isDisabled}
                      place={state?.place}
                      coverage={state?.coverage}
                      shift={state?.shift}
                      resume={state?.resume}
                    />
                  </S.Container>
                </S.Overflow>
              </>
            )
          }}
        </Tooltip>
      )}
    </>
  )
}

export default TooltipComponent
