import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { isArray } from 'lodash'
import remove from 'lodash/remove'
import { Checkbox } from 'antd'
import { CheckboxChangeEventTarget } from 'antd/es/checkbox/Checkbox'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import CampaignIcon from '@/components/ui/atoms/icon/assets/campaign.svg'
import SearchIcon from '@/components/ui/atoms/icon/assets/search.svg'
import VisibilityIcon from '@/components/ui/atoms/icon/assets/visibility.svg'
import * as S from './styled'

const Aside: React.FC<any> = ({ campaigns, colors }) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      let newCampaigns: Array<string> = []
      const target = e.target as CheckboxChangeEventTarget
      if (target.checked) {
        newCampaigns = isArray(values.campaigns)
          ? [...values.campaigns, target.value]
          : [values.campaigns, target.value]
        return setFieldValue('campaigns', newCampaigns)
      }

      if (isArray(values.campaigns)) {
        newCampaigns = [...values.campaigns]
        remove(newCampaigns, (campaign) => campaign === target.value)
      }

      return setFieldValue('campaigns', newCampaigns)
    },
    [values.campaigns],
  )

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('campaignsFilter', (e.target as HTMLInputElement)?.value)
  }

  const filteredCampaigns = useMemo(
    () =>
      campaigns
        ?.filter((campaign: any) => {
          return values.campaignsFilter
            ? campaign.value.toLowerCase().includes(values.campaignsFilter.toLowerCase())
            : true
        })
        .sort((a: any, b: any) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
        .sort((a: any, b: any) => values.campaigns?.includes(b.value) - values.campaigns?.includes(a.value)),
    [campaigns, values.campaignsFilter, values.campaigns],
  )

  return (
    <S.Wrapper>
      <S.Header>
        <CampaignIcon width={24} height={24} />
        <S.Title>Campaigns</S.Title>
      </S.Header>
      <S.Input
        onChange={handleFilter}
        placeholder="Start searching campaign"
        name="campaignsFilter"
        prefix={<SearchIcon fill="rgba(255, 255, 255, 0.4)" height={16} width={16} />}
      />
      <Checkbox.Group defaultValue={isArray(values.campaigns) ? values.campaigns : [values.campaigns]} name="campaigns">
        <S.Row>
          {filteredCampaigns?.map(({ value }: { value: any }, index: number) => (
            <S.Checkbox
              checked={values.campaigns?.includes(value)}
              onChange={onChange}
              key={`campaign-checkbox-${index}-`}
              value={value}
            >
              <S.Content>
                <S.Tag color={colors[value]} />
                <S.Text>{value}</S.Text>
                <S.Icon>
                  <VisibilityIcon width={20} />
                </S.Icon>
              </S.Content>
            </S.Checkbox>
          ))}
        </S.Row>
      </Checkbox.Group>
    </S.Wrapper>
  )
}

export default Aside
