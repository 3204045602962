import React from 'react'
import dayjs from 'dayjs'
import CampaignIcon from '@/components/ui/atoms/icon/assets/campaign.svg'
import CategoryIcon from '@/components/ui/atoms/icon/assets/category.svg'
import DateIcon from '@/components/ui/atoms/icon/assets/date.svg'
import RegionIcon from '@/components/ui/atoms/icon/assets/region.svg'
import * as S from '../styled'

const Tags: React.FC<any> = ({ maxWidth, date, category, region, campaign }) => {
  return (
    <S.Tags>
      <S.TagRow>
        <S.Tag>
          <S.Icon>
            <DateIcon />
          </S.Icon>
          <S.Value>{dayjs(date).format('D MMM YYYY')}</S.Value>
        </S.Tag>
        <S.Tag style={{ maxWidth }}>
          <S.Icon>
            <CategoryIcon />
          </S.Icon>
          <S.Value>{category}</S.Value>
        </S.Tag>
      </S.TagRow>
      <S.TagRow>
        <S.Tag>
          <S.Icon>
            <RegionIcon />
          </S.Icon>
          <S.Value>{region}</S.Value>
        </S.Tag>
        <S.Tag style={{ maxWidth }}>
          <S.Icon>
            <CampaignIcon />
          </S.Icon>
          <S.Value>{campaign}</S.Value>
        </S.Tag>
      </S.TagRow>
    </S.Tags>
  )
}

export default Tags
