function getUniqueColor(n) {
    const rgb = [0, 0, 0];

    for (let i = 0; i < 24; i++) {
        rgb[i%3] <<= 1;
        rgb[i%3] |= n & 0x01;
        n >>= 1;
    }

    return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '')
}

export default getUniqueColor