import { createSlice } from '@reduxjs/toolkit'
import { AppState } from './'
import { HYDRATE } from 'next-redux-wrapper'
import { MenuProps } from 'antd'

enum LANG {
  UA = 'ua',
  ENG = 'eng',
}

export const items: MenuProps['items'] = [
  {
    key: LANG.UA,
    label: 'Оригінальна мова',
  },
  {
    key: LANG.ENG,
    label: 'English',
  },
]

// Type for our state
export interface AppConfig {
  appConfig: {
    lang: LANG
  }
}

// Initial state
const initialState: AppConfig = {
  appConfig: {
    lang: LANG.UA,
  },
}

// Actual Slice
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Action to set the filters state
    setAppConfig(state, { payload }) {
      state.appConfig = payload
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.appConfig,
      }
    },
  },
})

export const { setAppConfig } = appSlice.actions

export const selectAppConfig = (state: AppState) => state.app.appConfig

export default appSlice.reducer
