import React, { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Highcharts, { ChartOptions, Chart as HighchartsChart } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ChartModal from './modal'
import * as S from './styled'
import TooltipComponent from './tooltip'

const AccessibleLineChart: React.FC<any> = ({ series, categories }) => {
  const [pointData, setPointData] = useState<any>(null)
  const [pointModalOptions, setPointModalOptions] = useState({ pointIndex: 0, seriesIndex: 0 })

  // const const series = reports.reduce((acc, el) => {
  //   if (acc[el.date]) {
  //     acc[el.date].push(el)
  //   } else {
  //     acc[el.date] = [el]
  //   }
  //   return acc
  // }, {})

  const [chartOptions, setChartOptions] = useState(
    // {
    //             xAxis: {
    //               categories: ['A', 'B', 'C'],
    //             },
    //             series: [{ data: [1, 2, 3] }],
    //             plotOptions: {
    //               series: {
    //                 point: {
    //                   events: {
    //                     mouseOver(e) {
    //                       setHoverData(e.target.category)
    //                     },
    //                   },
    //                 },
    //               },
    //             },
    //           }
    {
      chart: {
        type: 'spline',
        height: '616px',
        backgroundColor: 'transparent',
        plotBackgroundColor: 'transparent',
      },

      colorAxis: {},

      legend: { enabled: false },

      title: {
        text: '',
        align: 'left',
      },
      //
      // subtitle: {
      //   text: 'Source: WebAIM. Click on points to visit official screen reader website',
      //   align: 'left',
      // },

      yAxis: {
        title: {
          text: '',
        },
        accessibility: {
          description: 'Percentage usage',
        },
        labels: {
          style: {
            color: 'rgb(255 255 255 / 50%)',
          },
        },
      },

      xAxis: {
        step: 1,
        lineColor: 'rgb(255 255 255 / 50%)',
        // crosshair: {
        //   width: 20,
        // },
        title: {
          text: '',
        },
        accessibility: {
          description: 'Time from December 2010 to September 2019',
        },
        categories: categories,
        labels: {
          style: {
            color: 'rgb(255 255 255 / 50%)',
          },
        },
      },

      tooltip: {
        useHTML: true,
        styles: {
          background: 'black',
        },
        formatter: function () {
          return null
        },
      },

      plotOptions: {
        series: {
          allowPointSelect: true,
          marker: {
            radius: 3,
            states: {
              select: {
                radius: 5,
              },
            },
          },
          stickyTracking: false,
          point: {
            events: {
              click: function (event: any) {
                /* eslint-disable */

                const pointIndex = event.point.index
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const seriesIndex = this!.series.index
                setPointModalOptions({ pointIndex, seriesIndex })
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.series.data[pointIndex].setState('select')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.series.data[pointIndex].setState('hover')
                const state = event.point.series.data[pointIndex].series.options.state[pointIndex]
                const isDisabled = state.disable
                console.debug('isDisabled >>> >> >', isDisabled)
                setPointData(
                  !isDisabled
                    ? {
                        ...(state || {}),
                      }
                    : null,
                )
                /* eslint-enable */
              },
              // mouseOut() {
              //   this.series.chart.update({
              //     tooltip: {
              //       enabled: false,
              //     },
              //   })
              // },
              // mouseOver() {
              //   this.series.chart.update({
              //     tooltip: {
              //       enabled: true,
              //     },
              //   })
              // },
              // mouseOut() {
              //   this.series.chart.update({
              //     tooltip: {
              //       enabled: false,
              //     },
              //   })
              // },
            },
          },
          cursor: 'pointer',
        },
      },

      series,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 550,
            },
            chartOptions: {
              chart: {
                spacingLeft: 3,
                spacingRight: 3,
              },
              xAxis: {
                categories: categories,
                title: '',
              },
              yAxis: {
                visible: false,
              },
            },
          },
        ],
      },
    },
  )

  useEffect(() => {
    setChartOptions((prev) => ({
      ...prev,
      series,
      xAxis: {
        ...prev.xAxis,
        categories: categories.map((el: string) => dayjs(el).format('MMM D')),
      },
    }))
  }, [series, categories])

  const [chart, setChart] = useState<HighchartsChart | null>(null)
  const callback = useCallback((chart: HighchartsChart) => {
    setChart(chart)
  }, [])

  // const handleDisablePoint = (id) => chart?.get(id)?.select(false, false)

  const handleClose = useCallback(() => {
    // handleDisablePoint(pointData?.id)
    setPointData(null)
    chart?.series[pointModalOptions.seriesIndex]?.data[pointModalOptions.pointIndex]?.select(false, false)
  }, [pointModalOptions.seriesIndex, pointData?.id])

  // const handleNext = useCallback(() => {
  //   if (pointHoverIndex > 0) {
  //     chart.series[4].data[pointHoverIndex - 1].setState()
  //     chart.tooltip.hide()
  //   }
  //   if (pointHoverIndex == chart.series[4].data.length) {
  //     setPointHoverIndex(0)
  //   }
  //
  //   chart.series[4].data[pointHoverIndex].setState('hover')
  //   chart.series[4].setState('select')
  //   chart.tooltip.refresh(chart.series[4].data[pointHoverIndex])
  //
  //   setPointHoverIndex((prev) => prev + 1)
  // }, [chart, pointHoverIndex])

  const handleChangePoint = useCallback(
    (type: 'next' | 'prev') => () => {
      let nextPointIndex = type === 'next' ? pointModalOptions.pointIndex + 1 : pointModalOptions.pointIndex - 1
      const itemsLength = chart?.series[pointModalOptions.seriesIndex].data.length || 0

      chart?.series[pointModalOptions.seriesIndex]?.data[pointModalOptions.pointIndex]?.select(false, false)

      if (nextPointIndex === itemsLength) {
        nextPointIndex = 0
      }

      if (nextPointIndex < 0) {
        nextPointIndex = itemsLength - 1
      }

      chart?.series[pointModalOptions.seriesIndex].data[nextPointIndex].setState('select')
      chart?.series[pointModalOptions.seriesIndex].data[nextPointIndex].setState('hover')
      // chart.series[pointModalOptions.seriesIndex].setState('select')
      // chart.tooltip.refresh(chart.series[pointModalOptions.seriesIndex].data[nextPointIndex])

      setPointModalOptions((prev) => ({
        ...prev,
        pointIndex: nextPointIndex,
      }))

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = chart?.series[pointModalOptions.seriesIndex].options.state[nextPointIndex]
      const isDisabled = state.disable
      setPointData(state)
    },
    [pointModalOptions.pointIndex, pointModalOptions.seriesIndex],
  )

  return (
    <S.Wrapper>
      <S.Title>The volume of campaign mentions, by period</S.Title>
      <HighchartsReact callback={callback} highcharts={Highcharts} options={chartOptions} />
      <ChartModal
        handleNextPoint={handleChangePoint('next')}
        handlePrevPoint={handleChangePoint('prev')}
        isOpen={!!pointData}
        data={pointData}
        handleClose={handleClose}
      />
      <TooltipComponent chart={chart} />
    </S.Wrapper>
  )
}

export default AccessibleLineChart
