import styled from 'styled-components'
import { Button as AButton, Dropdown as ADropdown } from 'antd'

export const LangButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > div {
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
`

export const LangButton = styled(AButton)`
  && {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: 24px;
    height: 100%;
    min-width: 24px;
    padding: 0 8px;
    margin: 0 16px;
    box-sizing: content-box;
    align-self: center;

    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      fill: #f6425e;
    }

    &:hover {
      path {
        fill: #4096ff;
      }
    }
  }
`

export const Dropdown = styled(ADropdown)`
  && > div {
    background: black;
  }
`
