import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { selectAppConfig } from '@core/store/appSlice'
import { selectFiltersOptions } from '@core/store/repotsSlice'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import useRequest from '@/apis/external-api/useRequest'
import { API } from '@/apis/internal-api'

export enum Region {
  Russia = 'Russia',
  China = 'China',
  Ukraine = 'Ukraine',
}

type PageMeta = {
  page: number
  take: number
  itemCount: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

type Report = {
  id: number
  date: Date
  region: Region
  category: string
  campaign: string
  keywords: string
  youscan_link: string
  authors: string
  place: number
  coverage: number
  shift: number
  resume: string
  resume_long: string
  accepted: boolean
  critical_mistakes: boolean
}

interface ReportsResponse {
  data: Report[]
  meta: PageMeta
}

// const dateFrom = '2022-11-01T01:00:00.000Z'
// const dateTo = '2022-11-05T22:00:00.000Z'

const getDiffDays = (dateFrom: Dayjs, dateTo: Dayjs) => {
  return dateTo.diff(dateFrom, 'day')
}

export default function useMainHook() {
  const router = useRouter()
  const { query } = useRouter()
  const reportsOptions = useSelector(selectFiltersOptions)
  const { lang } = useSelector(selectAppConfig)
  const { campaigns = [], category, dateFrom, dateTo, region } = query
  const requestQuery = `?take=1000&page=1&order=ASC&category=${category}&campaigns=${campaigns}&baseregion=${region}&dateFrom=${dateFrom}&dateTo=${dateTo}&lang=${lang}`
  const {
    data: reportsData,
    loading,
    error,
  } = useRequest(
    {
      url: `${API.CHART_SERVER.INDEX}/reports${requestQuery}`,
    },
    { isDisable: !reportsOptions || !campaigns || !category || !dateFrom || !dateTo || !region, isShouldUpdate: true },
  )
  useEffect(() => {
    router.replace('', undefined, { shallow: true })
  }, [])

  const categories: string[] = []

  const dayjsDateFrom = dayjs(dateFrom as string)
  const dayjsDateTo = dayjs(dateTo as string)
  const diff = getDiffDays(dayjsDateFrom, dayjsDateTo) + 1

  for (let i = 0; i < diff; i++) {
    categories.push(
      dayjs(dateFrom as string)
        .add(i, 'days')
        .toISOString(),
    )
  }

  const series = map(Object.entries(groupBy(reportsData?.data, 'campaign')), (entry, i) => {
    const seriesItem: any = {
      name: entry[0],
      data: [],
      state: [],
      color: reportsOptions?.colors[entry[0]],
    }
    if (entry[1].length === categories.length) {
      console.log(' >>>>>>>>>>> VARIANT __ 1 <<<<<<<<<<<<')
      seriesItem.state = []
      seriesItem.data = entry[1].map((report, index) => {
        seriesItem.state[index] = report
        return {
          id: report.id,
          y: report.coverage,
        }
      })
      return seriesItem
    }

    categories.forEach((date, index) => {
      const entryElement = entry[1][index]
      const newId = `${entry[0]}-${entry[1][0].category}-${index}`
      const emptyData = { id: newId, y: 0 }
      const emptyState = { campaign: entry[0], region: entry[1][0].region, date, disable: true }
      if (entryElement && dayjs(date).date() === dayjs(entryElement.date).date()) {
        seriesItem.data[index] = {
          id: entryElement.id,
          y: entryElement.coverage || 0,
        }
        seriesItem.state[index] = { ...entryElement, date }
        return
      }
      if (entryElement && dayjs(date).date() !== dayjs(entryElement.date).date()) {
        const currentIndex = getDiffDays(dayjsDateFrom, dayjs(entryElement?.date))
        seriesItem.data[currentIndex] = {
          id: entryElement.id,
          y: entryElement?.coverage || 0,
        }
        seriesItem.state[currentIndex] = { ...entryElement }

        if (!seriesItem.data[index]) {
          seriesItem.data[index] = emptyData
          seriesItem.state[index] = emptyState
        }
      }

      if (!entryElement && !seriesItem.data[index]) {
        seriesItem.data[index] = emptyData
        seriesItem.state[index] = emptyState
        return
      }
    })
    return seriesItem
  })

  // const series = Object.entries(
  //   groupBy(
  //     uniqWith(reportsData?.data, (a, b) => a.campaign === b.campaign && a.date === b.date),
  //     'campaign',
  //   ),
  // )

  return {
    series: series || [],
    categories,
    loading,
    error,
  }
}
